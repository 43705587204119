// NOTE: this is a PoC showing static sample data
(function () {
    'use strict';

    angular.module("aerosApp")
        .controller('OTDRResultsCtrl', OtdrResultsController);

    OtdrResultsController.$inject = ['$scope', '$rootScope', '$stateParams', '$controller', '$uibModal', 'OTDRDemo',
        '$state', 'OTDRResultsService', '$timeout', 'aerosApi', 'UnitsMeasurementService', 'UserProfileService',
        'Notification', 'projectSummary', '$window', 'routesConstant', 'PageService'];

    function OtdrResultsController($scope, $rootScope, $stateParams, $controller, $uibModal, OTDRDemo, $state,
                                   OTDRResultsService, $timeout, aerosApi, UnitsMeasurementService, UserProfileService,
                                   Notification, projectSummary, $window, routesConstant, PageService) {
        $controller('OLTSResultsCtrl', {$scope: $scope, projectSummary: projectSummary});

        var CUSTOMER = routesConstant.CUSTOMER;

        angular.extend($scope, {
            isToolApplied: isToolApplied,
            isResultsExists: isResultsExists,
            showEvents: showEvents
        });

        function isToolApplied() {
            if ($stateParams.resultType !== 'all') {
                if (typeof $scope.summary !== 'undefined') {
                    var fb = $scope.summary.fiberGroups.find(function (obj) {
                        return obj.uuid === $scope.fibergroupId;
                    });
                }

                return toolTypeCriteria(fb);
            } else {
                return typeof $scope.summary !== 'undefined' && $scope.summary.fiberGroups;
            }
        }

        function toolTypeCriteria(fb) {
            return fb && fb.summaryResults && 'OTDR' in fb.summaryResults;
        }

        function isResultsExists() {

            if($scope.fiberGroups == undefined){
                return false;
            }

            for(var i = 0; i < $scope.fiberGroups.length; i++){
                if($scope.fiberGroups[i].results !== undefined){
                    if($scope.fiberGroups[i].results.fibers.length > 0){
                        return true;
                    }
                }
            }
            return false;
        };

        (function init() {
            OTDRResultsService.getAllResults({
                orgId: $rootScope.orgId,
                projectId: $stateParams.id
            }).then(function (results) {
                $scope.fiberGroups = $stateParams.qry
                    ? results.filter(function (element) {
                        return element.fiberGroupUuid == $stateParams.qry.split('=')[1];
                    })
                    : results;

                setDefaults($scope.fiberGroups);
                OTDRResultsService.calculatePrePostStatuses($scope.fiberGroups);
                OTDRResultsService.calculatePrePostGrouping($scope.fiberGroups);
            }).then(function () {
                if ($stateParams.linkmap && $stateParams.linkmap > 0) {

                    var fiberLinkmap, fiberResult;

                    angular.forEach($scope.fiberGroups, function (fiberGroup) {
                        angular.forEach(fiberGroup.results.fibers, function (fiber) {
                            if (+fiber.fiberId === +$stateParams.linkmap) {
                                fiberLinkmap = fiber;
                                fiberResult = fiberGroup;

                            }
                        });
                    });

                    if (fiberLinkmap) {
                        showEvents(fiberLinkmap, fiberResult);
                    }
                    /*
                     <div ng-repeat="fg in fiberGroups track by $index" class="col-xs-12">
                     <div class="row" ng-repeat="result in fg.results.fibers track by $index"*/


                }
            });

            $scope.iconMap = {
                "Failed": "times",
                "PassedOrComplete": "check",
                "Incomplete": "question",
                "notEval": "question",
                "NotApplicable": "question"
            };

            $scope.tool = $stateParams.testType;
        })();

        (function testInit() {

            var userProfile = UserProfileService.get();

            $scope.unit = UnitsMeasurementService.getUnit(userProfile.user.preferredUnitOfMeasure);

            $scope.lossDistUnit = userProfile.user.preferredLossDistanceUnit;

            $scope.results = true;
            $scope.groupRows = [{
                name: 'End1>End2',
                id: "MainToRemote"
            }, {
                name: 'End2>End1',
                id: "RemoteToMain"
            }, {
                name: 'Bidir Ave',
                id: "Average"
            }];
            $scope.groupSets = [{
                name: 'Faults',
                subSets: [{
                    name: 'End1>End2',
                    type: 'faultsMainToRemote',
                    typelength: 0
                }, {
                    name: 'End2>End1',
                    type: 'faultsRemoteToMain',
                    typelength: 0
                }, {
                    name: 'Bidir Ave',
                    type: '',
                    typelength: 0
                }]
            }, {
                name: 'Loss(dB)',
                subSets: [{
                    name: 'End1>End2',
                    type: 'lossMainToRemote',
                    typelength: 3
                }, {
                    name: 'End2>End1',
                    type: 'lossRemoteToMain',
                    typelength: 3
                }, {
                    name: 'Bidir Ave',
                    type: 'la',
                    typelength: 3
                }]
            }, {
                name: 'ORL(dB)',
                subSets: [{
                    name: 'End1>End2',
                    type: 'orlMainToRemote',
                    typelength: 2
                }, {
                    name: 'End2>End1',
                    type: 'orlRemoteToMain',
                    typelength: 2
                }, {
                    name: 'Bidir Ave',
                    type: '',
                    typelength: 2
                }]
            }, {
                name: 'Atten(' + ($scope.lossDistUnit || 'dB/km') + ')',
                subSets: [{
                    name: 'End1>End2',
                    type: 'attenuationMainToRemote',
                    typelength: 3
                }, {
                    name: 'End2>End1',
                    type: 'attenuationRemoteToMain',
                    typelength: 3
                }, {
                    name: 'Bidir Ave',
                    type: 'aa',
                    typelength: 3
                }]
            }, {
                name: 'Refl(dB)',
                width: "110px",
                subSets: [{
                    name: 'A>B',
                    type: 'reflectanceMainToRemote',
                    typelength: 2
                }, {
                    name: 'B>A',
                    type: 'reflectanceRemoteToMain',
                    typelength: 2
                }, {
                    name: 'Ave',
                    type: 'reflectanceAverage',
                    typelength: 2
                }]
            }];

        })();

        $scope.filterTypes = [{
            name: "Unidirectional End1 -> End2",
            value: 'End1>End2'
        }, {
            name: "Unidirectional End2 -> End1",
            value: 'End2>End1'
        }, {
            name: "Bidirectional Average",
            value: 'Bidir Ave'
        }, {
            name: "Unidirectional and Average",
            value: 'End1>End2&End2>End1&Ave'
        }
        ];

        $scope.isFloat = function isFloat(n) {
            return n === +n && n !== (n | 0);
        };

        function showEvents(result, fg) {
            launchEventDetailPopup(result, fg).result.then(function(results) {
                if (results.action === 'traceviewer') {
                    $state.go(CUSTOMER.PROJECTS_PROJECT_TRACEVIEWER.stateName,
                        {fiberGroupId: fg.fiberGroupUuid, fiberId: result.fiberId, fiberName: result.fiberGroup});
                }
            });
        }

        $scope.openTraceViewer = function (fg, result) {
            var fiberId = result.fiberId,
                fiberGroupId = fg.fiberGroupUuid,
                fiberName = fg.results.name;
            window.fromLinkMap = false;
            $state.go(CUSTOMER.PROJECTS_PROJECT_TRACEVIEWER.stateName, {
                fiberGroupId: fiberGroupId,
                fiberId: fiberId,
                fiberName: fiberName
            });
        };

        function launchEventDetailPopup(result, fg) {
            return $uibModal.open({
                templateUrl: 'EventListModal.html',
                resolve: {
                    result: function () {
                        return result;
                    },
                    fg: function () {
                        return fg;
                    },
                    imageMap: function () {
                        return OTDRDemo.imageMap;
                    },
                    iconMap: function () {
                        return $scope.iconMap;
                    },
                    unit: function () {
                        return $scope.unit;
                    },
                    lossDistUnit: function () {
                        return $scope.lossDistUnit;
                    }
                },
                controller: ["$scope", "$uibModalInstance", "$timeout", "result", "imageMap", "iconMap", "unit", "lossDistUnit", "fg",
                    function ($scope, $uibModalInstance, $timeout, result, imageMap, iconMap, unit, lossDistUnit, fg) {
                        $scope.result = result;
                        $scope.fg = fg;
                        $scope.imageMap = imageMap;
                        $scope.iconMap = iconMap;
                        $scope.unit = unit;
                        $scope.lossDistUnit = lossDistUnit;

                        $scope.groupRows = [{
                            name: 'End1>End2',
                            id: "MainToRemote"
                        }, {
                            name: 'End2>End1',
                            id: "RemoteToMain"
                        }, {
                            name: 'Bidir Ave',
                            id: "Average"
                        }];

                        $scope.groupSets = [{
                            name: 'Loss(dB)',
                            width: "145px",
                            subSets: [{
                                name: 'A>B',
                                type: 'lossMainToRemote',
                                typelength: 3
                            }, {
                                name: 'B>A',
                                type: 'lossRemoteToMain',
                                typelength: 3
                            }, {
                                name: 'Ave',
                                type: 'lossAverage',
                                typelength: 3
                            }]
                        }, {
                            name: 'Refl(dB)',
                            width: "110px",
                            subSets: [{
                                name: 'A>B',
                                type: 'reflectanceMainToRemote',
                                typelength: 2
                            }, {
                                name: 'B>A',
                                type: 'reflectanceRemoteToMain',
                                typelength: 2
                            }, {
                                name: 'Ave',
                                type: 'reflectanceAverage',
                                typelength: 2
                            }]
                        }, {
                            name: 'Cum Loss(dB)',
                            width: "110px",
                            subSets: [{
                                name: 'A>B',
                                type: 'cumulativeLossMainToRemote',
                                typelength: 3
                            }, {
                                name: 'B>A',
                                type: 'cumulativeLossRemoteToMain',
                                typelength: 3
                            }, {
                                name: 'Ave',
                                type: 'cumulativeLossAverage',
                                typelength: 3
                            }]
                        }];

                        $scope.fiberSets = [{
                            name: 'Loss(dB)',
                            width: "145px",
                            subSets: [{
                                name: 'A>B',
                                type: 'lossMainToRemote',
                                typelength: 3
                            }, {
                                name: 'B>A',
                                type: 'lossRemoteToMain',
                                typelength: 3
                            }, {
                                name: 'Ave',
                                type: 'lossAverage',
                                typelength: 3
                            }]
                        }, {
                            name: 'Atten(' + ($scope.lossDistUnit || 'dB/km') + ')',
                            width: "110px",
                            subSets: [{
                                name: 'A>B',
                                type: 'attenuationMainToRemote',
                                typelength: 2
                            }, {
                                name: 'B>A',
                                type: 'attenuationRemoteToMain',
                                typelength: 2
                            }, {
                                name: 'Ave',
                                type: 'attenuationAverage',
                                typelength: 2
                            }]
                        }, {
                            name: 'Cum Loss(dB)',
                            width: "110px",
                            subSets: [{
                                name: 'A>B',
                                type: 'cumulativeLossMainToRemote',
                                typelength: 3
                            }, {
                                name: 'B>A',
                                type: 'cumulativeLossRemoteToMain',
                                typelength: 3
                            }, {
                                name: 'Ave',
                                type: 'cumulativeLossAverage',
                                typelength: 3
                            }]
                        }];

                        $uibModalInstance.opened.then(function (a) {
                            $timeout(function () {
                                $('.modal-dialog').addClass('expand');
                            }, 0);

                            return a;
                        });

                        $scope.goToTraceviewer = function() {
                            window.fromLinkMap = true;
                            $uibModalInstance.close({action:'traceviewer'});
                        }

                    }]
            });
        }

        function setDefaults(testResults) {
            angular.forEach(testResults, function (testResult) {
                if (testResult.results) {
                    angular.forEach(testResult.results.fibers, function (fiber) {
                        var eventCount = 0;

                        angular.forEach(fiber.events, function (event) {
                            if ((event.type === 'Start') && !event.status) {
                                event.status = 'NoLaunchCable';
                            }

                            if (event.type === 'Event') {
                                eventCount++;
                            }
                        });

                        fiber.eventCount = eventCount;
                        fiber.fiberGroup = testResult.results.name;
                    });
                }
            });

            testResults.sort(function (a, b) {

                if(a.results === undefined || b.results === undefined){
                    return 1;
                }

                return a.results.name.localeCompare(b.results.name);
            });
        }

        $scope.getAdditionalClasses = function (event) {
            return 'OTDR';
        };

        $scope.showFiberGroupInfo = function (fg) {
             var fiberGroup = {
                name: fg.results.name,
                id: fg.results.id,
                cableType: fg.results.fiberType,
                fiberType: fg.subFiberType,
                fiberMode: fg.results.fiberMode,
                startFiber: fg.startFiber,
                fiberCount: fg.fiberCount,
                connectionCount: fg.connectionCount,
                spliceCount: fg.spliceCount,
                mpoPolarity: fg.mpoPolarity,
                end1Name: fg.end1Name,
                end1Connector: fg.end1Connector,
                end1Link: fg.end1Link,
                end2Name: fg.end2Name,
                end2Connector: fg.end2Connector,
                end2Link: fg.end2Link,
                comment: fg.comment
             };

            $uibModal.open({
                size: "md",
                templateUrl: 'fiberGroupInfoModalOtdr.html',
                controller: ["$scope", "fiberGroup", function ($scope, fiberGroup) {
                    $scope.fiberGroup = fiberGroup;
                }],
                resolve: {
                    fiberGroup: function () {
                        return fiberGroup;
                    }
                }
            });
        };

        $scope.showFiberInfo = function (fiber) {
            $uibModal.open({
                size: "md",
                templateUrl: 'fiberInfoModal.html',
                controller: ["$scope", "$filter", "UserProfileService", "UnitsMeasurementService",
                    function ($scope, $filter, UserProfileService, UnitsMeasurementService) {
                    $scope.fiber = fiber;
                    $scope.dateFormat = 'MM/dd/yyyy @ h:mma';

                    var userProfile = UserProfileService.get();

                    $scope.lossDistUnit = userProfile.user.preferredLossDistanceUnit;
                    $scope.unit = UnitsMeasurementService.getUnit(userProfile.user.preferredUnitOfMeasure);

                    if (fiber.runAsAcquisitionParameters) {
                        $scope.acquisitionParams = [{
                            caption: 'Test Taken Time',
                            value: $filter('date')(fiber.testedOn, $scope.dateFormat), big: true
                        }, {
                            caption: 'Test Mode',
                            value: fiber.runAsAcquisitionParameters.testMode
                        }, {
                            caption: 'Wavelengths',
                            value: getWavelengthList(fiber.runAsAcquisitionParameters.wavelengths)
                        }, {
                            caption: 'Range',
                            value: UnitsMeasurementService.metersTo($scope.unit.FULL,
                                fiber.runAsAcquisitionParameters.range) + '('+$scope.unit.SHORT+')'
                        }, {
                            caption: 'Pulse Width',
                            value: fiber.runAsAcquisitionParameters.pulseWidth / 1000 + '(us)'
                        }, {
                            caption: 'Averaging Time',
                            value: fiber.runAsAcquisitionParameters.averagingTime / 1000 + '(s)'
                        }, {
                            caption: '# of Averages',
                            value: fiber.runAsAcquisitionParameters.numberOfAverages
                        }, {
                            caption: 'Resolution',
                            value: fiber.runAsAcquisitionParameters.resolution
                        }, {
                            caption: 'DataSpacing',
                            value: UnitsMeasurementService.metersTo($scope.unit.FULL,
                                OTDRResultsService.convertDataSpacingToMeters(
                                    fiber.runAsAcquisitionParameters.dataSpacing,
                                    fiber.runAsAcquisitionParameters.helixFactor
                                ))
                                + '('+$scope.unit.SHORT+')'
                        }, {
                            caption: 'LaunchCable',
                            value: UnitsMeasurementService.metersTo($scope.unit.FULL,
                                fiber.runAsAcquisitionParameters.launchCable.value) + '('+$scope.unit.SHORT+')'
                        }, {
                            caption: 'ReceiveCable',
                            value: UnitsMeasurementService.metersTo($scope.unit.FULL,
                                fiber.runAsAcquisitionParameters.receiveCable.value) + '('+$scope.unit.SHORT+')'
                        }, {
                            caption: 'HelixFactor',
                            value: Number.parseFloat(fiber.runAsAcquisitionParameters.helixFactor).toFixed(3)
                        }
                            /*{caption: 'apd Gain', value: fiber.runAsAcquisitionParameters.apdGain},
                            {caption: 'tia Gain', value: fiber.runAsAcquisitionParameters.tiaGain},
                            {caption: 'acq Flags', value: fiber.runAsAcquisitionParameters.acqFlags},*/
                        ];
                    }

                    function getWavelengthList(wavelengths) {
                        return wavelengths.map(function(wl){
                            return wl.slice(1);
                        }).join(', ');
                    }
                }]
            })
        };
        $scope.exportSorFile = function (fg, result) {
            var wavelengths = Object.keys(result.wavelengths).map(function (item) {
                    return parseInt(item.substr(1))
                }),
                option = {
                    orgId: $rootScope.orgId,
                    projectId: fg.projectUuid,
                    fiberGroupId: fg.fiberGroupUuid,
                    fiberId: result.fiberId,
                    wavelengths: wavelengths,
                    otdrFileExportType: 'SOR'
                };
            return aerosApi.getExportSorUrl(option)
                .then(function (data) {
                    $window.open(data.data, '_blank');
                    //$scope.sorFileUrlToDownload = data.data;
                    //document.getElementById('sorFileUrlToDownload').click();
                }, function (err) {
                    Notification.error(err.data.message);
                })


        };

        $scope.getPrecision = function (field, value) {
            if (typeof value === 'undefined') {
                return '--';
            }

            switch (field.name) {
                case 'Faults':
                    return value.toFixed(0);
                case 'Loss(dB)':
                    return value.toFixed(3);
                case 'ORL(dB)':
                    return value.toFixed(2);
                case 'Atten(' + ($scope.lossDistUnit || 'dB/km') + ')':
                    if ($scope.lossDistUnit !== 'dB/km') {
                        var converters = UnitsMeasurementService.getConverters('dB/km|' + $scope.lossDistUnit);
                        if (converters.showUnitConverter) {
                            return converters.showUnitConverter(value).toFixed(2);
                        }
                    }
                    return value.toFixed(2);

                default:
                    return value;
            }
        };

        $(window).on('hashchange', function (event) {
            var mc = document.getElementsByClassName("modal-content");
            var i;
            for(i=0;i<mc.length;i++) {
                $(".btn-close").click();
            }

        });
    }
})();
